.open_button {
	position: fixed;
	z-index: 100;
	top: 45px;
	left: 14px;

	svg {
		font-size: 16px;
	}
}

.test {
	background-color: #000 !important;
	color: #fff !important;
}
