@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.submit_page {
	.edit_form {
		margin-top: 1rem;

		@include media($phone_all...) {
			margin-top: 0;
		}

		> div {
			scroll-margin-top: 100px;
			align-content: flex-start;
		}

		.video_info {
			display: flex;
			border: 0.1rem solid #2f3336;
			border-radius: 8px;

			img {
				width: 9rem;
				border-right: 0.1rem solid #2f3336;
				border-radius: 8px 0 0 8px;
			}

			h3 {
				padding: 6px;
				font-size: 14.4px;
				font-weight: 700;
			}

			h4 {
				padding: 6px;
				font-size: 0.7rem;
			}
		}
	}
}
