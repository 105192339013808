@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.artwork_page {
	.leftColumn {
		padding-right: 6rem;

		@include media($phone_all...) {
			padding-right: 0 !important;
		}
	}

	.rightColumn {
		padding-left: 6rem;

		@include media($phone_all...) {
			padding-left: 13px !important;
		}
	}

	.dropB {
		margin-bottom: 30px;
	}

	.edit_form {
		margin-top: 1rem;

		@include media($phone_all...) {
			margin-top: 0;
		}

		> div {
			scroll-margin-top: 100px;
		}

		.video_info {
			display: flex;
			border: 0.1rem solid #2f3336;
			border-radius: 8px;

			img {
				width: 9rem;
				border-right: 0.1rem solid #2f3336;
				border-radius: 8px 0 0 8px;
			}

			h3 {
				padding: 6px;
				font-size: 14.4px;
				font-weight: 700;
			}

			h4 {
				padding: 6px;
				font-size: 0.7rem;
			}
		}
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;

		button {
			border-radius: 20px;
			margin-left: 12px;
		}
	}
}
