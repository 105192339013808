@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.page_indicator {
	display: inline-flex;
	margin: 0 18px;
	justify-content: center;
	align-items: center;

	@include media($phone_all...) {
		margin-top: 1rem;
		margin-right: 30px;
	}

	&:hover {
		cursor: pointer;
	}

	p {
		font-size: 14px;
		font-weight: 700;
		color: #ffffff59;

		@include media($phone_all...) {
			font-size: 12px;
			line-height: 12px;
		}

		&.active {
			color: #53b9ea;
		}
	}

	div {
		height: 100%;
		display: flex;
		align-items: center;

		svg {
			font-size: 12px;
			margin-right: 6px;
			color: #ffffff59;
		}

		&.circle {
			svg {
				font-size: 6px;
				color: #53b9ea;
				box-shadow: 0px 0px 6px 2px #53b9ea;
				border-radius: 50%;
				margin-right: 12px;
			}
		}

		&.circleInactive {
			svg {
				font-size: 6px;
				color: #ffffff59;
				border-radius: 50%;
				margin-right: 12px;
			}
		}
	}
}
