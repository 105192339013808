@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.notfound_detail {
	margin-top: 10rem;
	max-width: 1080px !important;
	margin-bottom: 5rem;
	position: relative;
	z-index: 2;
	text-align: center;
	@include media($phone_all...) {
		margin-top: 5rem;
		padding: 0 10px;
	}

	.notfound {
		max-width: 800px;
		width: 100%;
		margin: 5rem auto;
		background: rgba(0, 0, 0, 0.65);
		border-radius: 20px;
		padding: 5rem 7rem;

		@include media($phone_all...) {
			padding: 1rem;
		}

		.notfound_icon {
			margin: 1rem 0;
			position: relative;

			svg:first-child {
				font-size: 2.5rem;
				position: relative;
				z-index: 2;
				left: 0px;
				top: -10px;
			}
			svg:nth-child(2) {
				font-size: 5rem;
				color: #4e4bde;
				z-index: 1;
				left: -20px;
				position: relative;
			}
		}
		h2 {
			font-size: 25px;
			color: #fff;
			font-weight: 600;
			line-height: 2.75rem;
			margin-bottom: 0.5rem;
			letter-spacing: 0;
			@include media($phone_all...) {
				margin-top: 1rem;
				line-height: 2rem;
			}
		}
		p {
			font-size: 1.25rem;
			line-height: 1.5rem;
		}
		.newsreelButton {
			background: #152d53;
			border-radius: 25px;
			font-weight: 400;
			line-height: 24px;
			font-size: 20px;
			color: #fff;
			margin-top: 2rem;
			padding: 0.75rem 2rem;
			@include media($phone_all...) {
				margin-bottom: 2rem;
			}
		}
	}
}
