@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.sidebar_newsreel {
	width: 16rem;
	min-width: 16rem;
	overflow: auto;

	@include media($phone_all...) {
		display: none;
		top: 11.5rem;
		z-index: 100;
		background: #000;
	}
}