.stake_item {
	.reviews {
		margin: 0px 0 16px 0;
		margin-top: 1rem;
		position: relative;

		p {
			font-size: 1rem;
			line-height: 1.2rem;
			font-weight: normal;
			color: #fff;
			margin: 0;
		}

		h5 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0.75rem;

			a {
				font-size: 14px;
				font-weight: 400;
			}
		}

		.review {
			max-width: 600px;
			background: rgba(0, 0, 0, 0.35);
			margin: 16px 0 16px 0;
			border-radius: 10px;
			position: relative;

			.heading {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				padding: 1.5rem 1.5rem 0 1.5rem;

				span {
					font-size: 1rem;
					align-self: flex-end;
					justify-self: end;
				}

				button {
					color: #fff;

					svg {
						font-size: 0.8rem;
					}
				}
			}

			.content {
				margin: 16px 0;
				padding: 0 1.5rem 0;
				line-height: 1.3rem;
				font-size: 0.9rem;
				font-weight: normal;
				font-family: 'Titillium Web', sans-serif;
				color: #fff;

				span {
					font-weight: 600;
				}
			}

			.footer {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 16px 0;

				span {
					display: flex;
					align-items: center;
				}

				.add_comment_btn {
					background: #3cbb5b;
					width: 35px;
					height: 35px;
					color: #fff;
					font-family: 'Titillium Web', sans-serif;
					border-radius: 50%;
					cursor: pointer;
					border: none;
					margin-left: auto;

					svg {
						width: 1rem;
					}

					&:hover {
						background: #083ea3;
					}
				}

				.reactions {
					button {
						background: #000;
						color: #fff;
						height: 40px;
						padding: 0 10px;
						min-width: auto;
						margin-left: 10px;

						&:hover {
							background: #083ea3;
						}

						img {
							height: 20px;
							margin-right: 4px;
						}

						div {
							font-size: 1rem;
						}
					}
				}
			}

			.reviewer {
				display: inline-flex;
				align-items: center;
				width: 100%;

				.name {
					font-size: 0.9rem;
					font-weight: normal;
					line-height: 2rem;
				}
			}

			.total_staked {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;

				img {
					width: 1.5rem;
					margin-right: 0.75rem;
				}

				span {
					font-size: 0.9rem;
				}
			}
		}
	}
}
