@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.home {
	position: relative;
	background: #0f0e18;
	max-width: 100%;
	width: 100%;

	.blanket {
		background: rgba(11, 11, 34, 0.9);
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		backdrop-filter: blur(87.5px);
	}

	.circle_one {
		position: absolute;
		z-index: 1;
		width: 787.14px;
		height: 770.37px;
		left: 528.75px;
		top: -13.5px;
		background: #fc165b;
		border-radius: 50%;

		@include media($phone_all...) {
			display: none;
		}
	}

	.circle_two {
		position: absolute;
		width: 787.14px;
		height: 770.37px;
		left: 205.22px;
		top: 67.65px;
		background: #b765c8;
		border-radius: 50%;

		@include media($phone_all...) {
			display: none;
		}
	}

	.circle_three {
		position: absolute;
		width: 787.14px;
		height: 770.37px;
		left: -334px;
		top: 202.9px;
		background: #fca016;
		border-radius: 50%;

		@include media($phone_all...) {
			display: none;
		}
	}

	.circle_four {
		position: absolute;
		width: 357.9px;
		height: 350.28px;
		left: 362.68px;
		top: 1196.54px;
		background: #16fcd2;
		border-radius: 50%;

		@include media($phone_all...) {
			display: none;
		}
	}
}
