@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.add_newsreel_form {
	width: 100%;
	margin-top: 2rem;
	padding: 1.25rem 2rem;
	position: relative;
	background: #1e1d47;
	border-radius: 1.5rem;
	max-width: 600px;
	@include media($phone_all...) {
		padding: 1rem;
	}

	.header {
		display: flex;
		justify-content: space-between;

		& > div {
			max-width: 50%;
		}
	}

	.thumbnails {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.thumbnail {
			display: flex;
			width: 100px;
			height: 100px;
			position: relative;
			overflow: hidden;
			margin: 6px 0px;
			align-items: center;
			justify-content: center;
			border-radius: 0.2rem;
			background: #21202e;
			margin-right: 12px;
			border-radius: 5px;

			button {
				position: absolute;
				position: absolute;
				right: -3px;
				top: -3px;
				color: #ffffff59;
			}

			img {
				width: 100%;
			}
		}
	}

	.actions {
		display: flex;
		justify-content: space-between;
	}
}
