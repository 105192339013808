@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dao {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.dao_desc {
		margin-left: 50px;

		@include media($phone_all...) {
			margin-left: 0;
		}

		> img {
			margin: 48px auto 0 auto;
			max-width: 570px;
			width: 100%;
		}

		.explanation {
			margin-top: 48px;
			flex-direction: column;
			text-align: left;
			align-content: top;
			flex-basis: 49%;

			@include media($phone_all...) {
				margin: 28px auto 0 auto;
				flex-basis: 90%;
			}

			h2 {
				font-size: 30px;
			}

			p {
				font-size: 16px;
				margin-bottom: 24px;
				color: rgba(255, 255, 255, 0.6);

				strong {
					color: rgba(255, 255, 255, 1);
				}
			}

			button {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 10px 25px;
				gap: 10px;
				background: linear-gradient(
					180deg,
					rgba(195, 98, 255, 0.1) 0%,
					rgba(93, 69, 246, 0.1) 100%
				);
				border: 2px solid #ffffff;
				backdrop-filter: blur(12.5px);
				border-radius: 5px;
				color: #fff;
				font-size: 16px;
				cursor: pointer;

				&:hover {
					background: linear-gradient(
						180deg,
						rgba(195, 98, 255, 0.15) 0%,
						rgba(93, 69, 246, 0.5) 100%
					);
				}
			}
		}

		.hero {
			position: relative;
			flex-direction: column;
			flex-basis: 49%;
			text-align: left;
			align-content: top;
			margin-top: 48px;
			padding-left: 90px;
			right: 0px;

			@include media($phone_all...) {
				padding-left: 0;
				right: -8px;
				margin: 48px auto 48px auto;
				flex-basis: 90%;
				display: none;
			}

			img {
				max-width: 486px;
				width: 100%;
			}
		}
	}
}
