@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.filters_item {
	width: 100%;
	position: relative;
	background: none;
	padding: 0.5rem 1rem 1rem;

	.links {
		a {
			color: #fff;
			font-size: 1rem;
			font-weight: 300;
			margin-top: 14px;

			svg {
				font-size: 1rem;
				margin-right: 14px;
			}
		}
		margin-bottom: 20px;
	}

	.close_btn {
		display: none;

		@include media($phone_all...) {
			right: 10px;
			top: 10px;
			display: block;
			position: absolute;

			svg {
				font-size: 16px;
			}
		}
	}

	.logo {
		display: none;

		@include media($phone_all...) {
			display: block;
			height: 40px;
		}
	}

	.sorter {
		float: left;
		font-size: 1.2rem;
		margin-top: 0.9rem;
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.select_box {
		display: flex;
		align-items: center;

		svg {
			margin-bottom: 15px;
			margin-right: 10px;
		}
	}

	.menu_item {
		display: flex;
		align-items: center;

		& > svg,
		& > img {
			height: 16px !important;
			width: 16px !important;
			margin-right: 3px;
		}

		.only_show_label {
			span {
				margin-left: -10px;
				color: #fff;
				font-size: 1rem;
				font-weight: 300;

				&:first-child {
					opacity: 0;
				}
			}

			&.active {
				span {
					color: #fff;
					font-weight: 600;
				}
			}
		}
	}

	h3 {
		letter-spacing: 0.1rem;
		margin: 0;
		display: inline-block;
		text-transform: uppercase;
		color: #fff;
		font-size: 0.8rem;
		font-weight: 300;
		width: 100%;

		@include media($phone_all...) {
			margin: 0;
		}

		&.mt_30 {
			margin-top: 30px;
			margin-bottom: 10px;

			@include media($phone_all...) {
				margin-top: 15px !important;
			}
		}

		&.mt_40 {
			margin-top: 40px;
		}
	}
}
