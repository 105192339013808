@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.onboard_join {
	margin-top: 122px;
	position: relative;
	z-index: 1;
	background: rgba(0, 0, 0, 0.75);
	border-radius: 20px;
	padding: 30px;

	@include media($phone_all...) {
		margin-top: 60px;
		padding: 1rem;
		border-radius: 0;
	}

	.right {
		display: flex !important;
		flex-direction: column;

		@include media($phone_all...) {
			padding-top: 0 !important;
		}

		.hero_container {
			margin-bottom: 0;
			padding: 0;
			padding-bottom: 0.5rem;
			overflow: hidden;

			@include media($phone_all...) {
				max-height: 150px;
				overflow: hidden;
			}

			img {
				@include media($phone_all...) {
					margin-top: 1rem;
				}
			}
		}

		img {
			border-radius: 8px;
			width: 100%;
		}

		.logo {
			margin-top: 0;
			width: 100%;
			display: flex;
			justify-content: left;
			align-items: center;

			img {
				max-width: 240px;
				margin-top: 0rem;

				@include media($phone_all...) {
					margin-top: 2rem;
					max-height: 100%;
					max-width: 100%;
				}
			}
		}

		ul {
			li {
				display: block;
				width: 100%;
				font-size: 0.9rem;
				font-weight: 600;
				list-style: disc;
			}
		}

		.title {
			font-size: 35px;
			line-height: 36px;
			margin-top: 16px;
			margin-bottom: 5px;
			font-weight: 600;

			@include media($phone_all...) {
				font-size: 30px;
				margin-bottom: 5px;
				line-height: 32px;
			}
		}

		.subtitle {
			font-size: 20px;
			line-height: 22px;
			margin-top: 0px;
			margin-bottom: 16px;
			font-weight: 600;
		}

		.subtext {
			@extend .subtitle;
			color: #00baef;

			@include media($phone_all...) {
				margin: 0;
				line-height: 22px;
				margin-bottom: 10px;
			}
		}
	}
}

.login_container {
	display: flex;
	justify-content: center;
}

.content {
	padding: 0;

	button {
		margin-top: 20px;
	}

	.back {
		font-size: 0.9rem;
		font-weight: 600;
		background: #2e2c6a !important;
		border-radius: 25px !important;
		color: #fff !important;
		padding: 0.5rem 2.5rem !important;
		float: right;

		&:disabled {
			color: #ffffff96 !important;
		}
	}

	.or {
		margin: 1rem;
		text-align: center;
	}

	.social_login {
		display: flex;
		justify-content: space-between;
		margin-right: 64px;
	}

	.fields {
		input {
			width: 100%;
		}

		.row {
			display: flex;
			justify-content: space-evenly;
			width: 100%;

			.last_name,
			.first_name {
				width: 95%;
				margin-bottom: 16px;
			}

			.last_name {
				position: relative;
				right: -7px;
			}
		}

		.email_input {
			margin-bottom: 24px;
			width: 100%;
		}

		.password_container {
			display: flex;
			margin-bottom: 12px;

			button {
				height: 45px;
			}

			input {
				width: 100%;
			}

			.form_helper {
				width: 100%;
			}
		}
	}
}

.title {
	color: #fff;
	font-size: 2rem;
	line-height: 2.3rem;
	font-weight: 600;
	margin-bottom: 0;

	@include media($phone_all...) {
		margin-bottom: -0.5rem;
	}

	@include media($phone_all...) {
		margin-bottom: -0.5rem;
	}

	span {
		font-weight: 400;
		font-size: 1rem;
		margin-top: 0.25rem;
		margin-bottom: 0;
		line-height: 1.3rem;
		display: block;
		letter-spacing: normal;

		@include media($phone_all...) {
			margin-top: 0.25rem;
		}

		span {
			color: #4cb6eb;
			display: inline;
		}
	}
}

.agree {
	margin: 20px 30px 10px;
	padding-left: 5px;

	@include media($phone_all...) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@include media($phone_all...) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	p {
		letter-spacing: normal;
		font-size: 10px;
		font-weight: 400;
		margin: 0;
		line-height: 18px;
		text-align: center;
		color: rgba(255, 255, 255, 0.5);
	}

	a {
		color: rgba(255, 255, 255, 0.5);
		cursor: pointer;
		text-decoration: underline;
	}

	svg {
		color: #fff;
		font-size: 18px;
	}

	.newsletters {
		span {
			letter-spacing: 1px;
			font-size: 0.7rem;
			font-weight: 300;

			svg {
				color: #fff;
				font-size: 14px;
			}
		}
	}
}

.otherLogin {
	margin-top: 24px;

	.walletCnct,
	.walletMobileCnct {
		text-align: center;
		font-size: 0.8rem !important;
		border-radius: 25px !important;
		border: 0.1rem double transparent !important;
		background-image: radial-gradient(circle at left top, #fe9dd9, #8ee6e2),
			radial-gradient(circle at left top, #d900c0, #016eda) !important;
		background-origin: border-box !important;
		background-clip: padding-box, border-box !important;
		margin: 10px 0 15px 0;
		width: 100%;
		color: #000;
		font-size: 15px;
		display: flex !important;
		align-items: center;
		justify-content: center;
		font-weight: 600;

		svg {
			color: #000;
			font-size: 14px;
		}

		.walletBtn {
			position: relative;

			svg {
				color: #000;
				margin-right: 10px;
				font-size: 14px;
			}
		}
	}

	.walletMobileCnct {
		display: block;
		font-size: 13px;

		img {
			width: 26px;
			width: 14px;
			margin-right: 10px;
			border-radius: 0px;
		}

		@include media($phone_all...) {
			display: block !important;
		}
	}
}

.login_box {
	background: rgba(0, 0, 0, 0.6);
	width: 100%;
	max-width: 348px;
	position: relative;
	background: linear-gradient(
		180deg,
		rgba(45, 42, 180, 0.5) 0%,
		rgba(20, 18, 78, 0.85) 50%
	);
	border: 1px solid #2e2bba;
	border-radius: 30px;
	color: #fff;
	padding: 32px 25px 20px;
	backdrop-filter: blur(5px);

	@include media($phone_all...) {
		max-width: 500px;
		margin: 0.5rem;
		padding: 15px;
	}

	.close_btn {
		position: absolute;
		right: 12px;
		top: 12px;
		z-index: 100;
	}

	input {
		background: #2e2c6a;
		border: 1px solid #5553da;
		border-radius: 8px;
	}
}
