@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.message_box {
	display: flex;
	align-content: center;
	width: 600px;
	background: #1e1d47;
	box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding: 1rem;
	margin-top: 1rem;

	@include media($phone_all...) {
		width: 100%;
	}

	img {
		float: left;
		margin-left: 1rem;
		margin-right: 1em;

		@include media($phone_all...) {
			width: 70px;
			margin-left: 0.5rem;
			margin-right: 0.5em;
		}
	}

	h1 {
		font-size: 1rem;
		line-height: 1.1rem;
		margin-top: 1rem;

		@include media($phone_all...) {
			margin-top: 0;
		}
	}
}
