@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.detail_page {
	.edit_form {
		margin-top: 1rem;

		@include media($phone_all...) {
			margin-top: 0;
		}

		> div {
			scroll-margin-top: 100px;
		}
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;

		button {
			border-radius: 20px;
			margin-left: 12px;
		}
	}
}
