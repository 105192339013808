@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.social_join {
	margin-top: 2rem;
	margin-bottom: 6rem;
	max-width: 1080px !important;
	position: relative;
	z-index: 2;
	display: flex !important;
	flex-direction: column;

	@include media($phone_all...) {
		margin-top: 5rem;
		padding: 0 10px;
	}

	.top_join {
		display: flex;
		position: fixed;
		width: 100%;
		align-items: center;
		justify-content: center;
		z-index: 100;
		left: 0;

		span {
			border-radius: 0px 0px 10px 10px;
			background: #152d53;
			position: absolute;
			top: -30px;
			font-size: 17px;
			font-style: normal;
			padding: 8px 100px;

			@include media($phone_all...) {
				text-align: center;
				padding: 8px 40px;
				border-radius: 0px;
			}

			a {
				cursor: pointer;
			}

			strong {
				font-weight: 700;
			}
		}
	}

	.column_holder {
		@include media($phone_all...) {
			flex-direction: column-reverse;
		}

		.project_info {
			@include media($phone_all...) {
				padding-top: 10px;
				margin-left: 1rem;
				width: 98%;
			}
		}
	}

	.subtitle {
		font-weight: 700;
		font-size: 16px;
		margin-top: 32px;
		margin-bottom: 12px;
		color: #ffffff;

		@include media($phone_all...) {
			margin-top: 10px;
		}
	}

	.cover_container {
		width: 100%;
		overflow: hidden;
		position: relative;
		display: flex;
		border-radius: 15px;
		padding: 0 !important;

		&.no_dashboard {
			max-height: 255px;
		}

		img {
			width: 100%;
			height: 100%;

			@include media($phone_all...) {
				height: 100%;
			}
		}
	}

	.top_detail_list {
		margin-bottom: 1%;
		margin-top: 1rem;

		h2 {
			font-size: 1.7rem;
			font-weight: 600;
			margin: 0 0;

			@include media($phone_all...) {
				font-size: 1.5rem;
			}

			span {
				margin-left: 15px;

				@include media($phone_all...) {
					margin-left: 4px;
				}

				a {
					color: $linkColorOther;
					font-size: 0.9rem;
					font-weight: 300;
				}
			}
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.owner {
				h3 {
					margin: 0;
					line-height: 1rem;
				}

				a {
					color: #bcb2aa;
					font-size: 1rem;

					@include media($phone_all...) {
						font-size: 14px;
					}

					> span {
						font-size: 0.7rem;
						font-weight: 100;
					}
				}
			}

			li {
				display: flex;
				font-size: 1rem;
				align-items: center;
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.social_info {
			li {
				width: auto;

				svg {
					color: #08f996;
					font-size: 16px;

					@include media($phone_all...) {
						font-size: 14px;
					}
				}
			}
		}
	}

	.avatar_container {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		@include media($phone_all...) {
			padding-top: 0 !important;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}

	.social_link {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		padding: 0.6rem 0 15px 0.5rem;

		li {
			margin: 0 12px 0 0;
			position: relative;
			list-style: none;
			display: inline-block;
			margin: 0.25rem 0.5rem;
			background: #000;
			padding: 0;
			border: 0.1rem solid #0b72a7;
			border-radius: 5px;
			width: 8rem;
			height: 4rem;

			&:last-child {
				margin-right: 0;
			}

			a,
			button {
				display: block;
				text-align: center;
				font-weight: 300;
				padding: 0.5rem 0;
				color: #fff;
				width: 100%;
				font-size: 1.2rem;
				line-height: 1rem;
				background: transparent;
				cursor: pointer;

				&:hover {
					background: #0b72a7;
				}

				&:disabled {
					opacity: 0.5;
				}
			}

			a {
				&[aria-disabled='true'] {
					opacity: 0.5;
				}
			}

			button {
				border: none;
			}

			div {
				position: absolute;
				bottom: 0;
				width: 100%;
				color: #fff;
				background: #2d3f41;
				font-size: 0.9rem;
				font-weight: 100;
				text-align: right;
				padding: 2px;
				border-radius: 0 0 3px 3px;

				img {
					display: inline;
					position: relative;
					top: 0.15rem;
					width: 1rem;
					margin-left: 0.15rem;
				}
			}
		}
	}

	.projectinfo {
		margin-top: 50px;
		float: left;
		display: block;
		width: 100%;
		padding-right: 23px;

		@include media($phone_all...) {
			margin-top: 0;
		}

		h5 {
			font-weight: 700;
			margin-bottom: 1rem;
			font-size: 32px;
			line-height: 24px;
		}

		ul {
			margin: 0 0;
			padding: 0;
			list-style: none;
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			li {
				margin-right: 10px;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 24px;
				padding: 4px 18px;
				border-radius: 20px;
				background: #00000080;
				margin-bottom: 10px;

				&:first-child {
					font-weight: 600;
					text-transform: uppercase;
					color: #000;

					@include media($phone_all...) {
						margin-right: 30%;
					}
				}
			}
		}

		.media {
			h3 {
				@extend .subtitle;
			}

			.images {
				display: flex;

				& > img {
					max-height: 130px;
					border: 1px solid #ffffff;
					border-radius: 11px;
					margin-right: 25px;
				}
			}
		}

		.documents {
			h3 {
				@extend .subtitle;
			}

			& > div {
				display: flex;
				justify-content: flex-start;

				a {
					font-weight: 300;
					font-size: 14px;
					line-height: 24px;
					color: #ffffff;
					background: rgba(0, 0, 0, 0.5);
					border-radius: 10px;
					height: 45px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					padding: 12px 18px;

					svg {
						color: #08f996;
						margin-right: 8px;
					}
				}
			}
		}

		.show_about {
			width: 100%;
			margin-top: 2.02rem;
			text-align: center;
			display: flex;
			justify-content: center;
			position: relative;

			button {
				background: rgba(0, 0, 0, 0.5);
				border: 1px solid rgba(255, 255, 255, 0.5);
				border-radius: 20px;
				padding: 4px 26px;
			}

			&.show_more::before {
				content: '';
				width: 100%;
				position: absolute;
				height: 5rem;
				bottom: 65px;
				background: linear-gradient(180deg, rgba(33, 32, 46, 0) 0, #121212 90%);
				z-index: 1;
			}
		}

		.about {
			display: block;
			color: #bcb2aa;
			line-height: 1.3rem !important;
			font-size: 0.9rem;
			margin: 0.5rem 0;

			&.showMore {
				max-height: 25.5rem;
				min-height: 25rem;
				overflow: hidden;
			}

			img {
				max-width: 100%;
			}

			h3 {
				@extend .subtitle;
			}

			p {
				display: block;
				color: #bcb2aa;
				line-height: 1.3rem;
				font-size: 0.9rem;
				margin: 0.5rem 0;
				letter-spacing: 0.1rem;
			}
		}
	}

	.join_form {
		display: flex;
		align-items: center;
		justify-content: center;

		.content {
			padding: 0;

			.fields {
				input {
					width: 82%;
				}

				.row {
					display: flex;
					width: 90%;

					.last_name,
					.first_name {
						width: 100%;
						margin-bottom: 12px;
					}
				}

				.email_input {
					margin-bottom: 12px;
				}

				.password_container {
					display: flex;
					margin-bottom: 12px;

					button {
						height: 45px;
					}

					input {
						width: 100%;
					}

					.form_helper {
						width: 100%;
					}
				}
			}
		}

		.title {
			color: #fff;
			font-size: 2rem;
			line-height: 2.3rem;
			padding: 0 0 16px;
			font-weight: 600;
			margin-bottom: 2rem;

			span {
				font-weight: 400;
				font-size: 1rem;
				margin-top: 0.75rem;
				margin-bottom: 0;
				line-height: 1.3rem;
				display: block;
				letter-spacing: 0.1rem;

				span {
					color: #4cb6eb;
					display: inline;
				}
			}
		}

		.actions {
			justify-content: flex-start;
			margin-top: 2rem;

			.agree {
				letter-spacing: 1px;
				font-size: 0.7rem;
				font-weight: 300;
				margin: 0;

				a {
					color: #fff;
					cursor: pointer;
					text-decoration: underline;
				}
			}

			.newsletters {
				span {
					letter-spacing: 1px;
					font-size: 0.7rem;
					font-weight: 300;

					svg {
						color: #fff;
						font-size: 14px;
					}
				}
			}
		}
	}

	.header_title {
		margin-top: 25px !important;
		position: relative;

		@include media($phone_all...) {
			margin-top: 10px !important;
		}

		p {
			font-weight: 400;
			font-size: 17px;
			line-height: 24px;
			color: #ffffff;

			@include media($phone_all...) {
				font-size: 14px;
			}
		}

		.greenlightHeader {
			span {
				svg {
					cursor: pointer;
					font-size: 0.9rem;
					color: #01b6df;
					opacity: 0.5;
					transition: all 200ms ease-in;

					&:hover {
						opacity: 1;
					}
				}
			}
		}

		.impactHeader {
			span {
				svg {
					cursor: pointer;
					font-size: 0.9rem;
					color: #01b6df;
					opacity: 0.5;
					transition: all 200ms ease-in;

					&:hover {
						opacity: 1;
					}
				}
			}

			@include media($phone_all...) {
				display: none;
			}
		}
	}

	.header_outside {
		position: relative;
		height: 155px;
		background: linear-gradient(90deg, #01bf52 13.13%, #01acbd 90.45%);
		margin-top: 1rem !important;
		margin-bottom: 1.5rem !important;
		border-radius: 15px;
		padding: 0 !important;
		border: 2px solid transparent;

		@include media($phone_all...) {
			height: fit-content;
			margin-top: 0.5rem !important;
		}

		.header {
			height: 100%;
			width: 100%;
			background-color: #121212;
			border-radius: 15px;

			@include media($phone_all...) {
				flex-direction: column;
			}

			.info_text {
				font-weight: 300;
				font-size: 10px;
				line-height: 14px;
				color: #8c8c8c;
				flex: 1;
				padding: 6px;
				margin-top: 0.5rem;
				margin-left: 1rem;
				display: flex;
				align-items: flex-start;

				svg {
					color: #8c8c8c;
					width: 12px;
					height: 12px;
					margin-right: 4px;
					margin-top: 2px;
				}
			}

			.socials {
				border-radius: 14px 0 0 14px;
				background-color: #000;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@include media($phone_all...) {
					border-radius: 14px 14px 0 0;
					padding: 10px;
				}

				& > div {
					flex: 1;
				}

				.top {
					display: flex;
					justify-content: space-evenly;
					align-items: flex-end;

					@include media($phone_all...) {
						flex-wrap: wrap;
					}

					& > div {
						background: linear-gradient(270deg, #d900c0 12.82%, #016eda 75.21%);
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 46px;
						margin-top: 2.25rem;

						@include media($phone_all...) {
							margin: 5px;
							width: 40%;
						}

						& > svg {
							display: none;
						}

						&.disabled {
							background: linear-gradient(
								270deg,
								#540046 12.82%,
								#04284f 75.21%
							);

							& > svg {
								display: block;
								position: absolute;
							}

							button {
								background-color: #07111e;
								color: #595959;

								svg {
									color: #113d47;
								}
							}
						}

						button {
							background: #152d53;
							border-radius: 10px;
							width: calc(100% - 2px);
							height: 44px;

							svg {
								color: #53abc9;
							}
						}
					}
				}

				p {
					@extend .info_text;
				}
			}
		}
	}

	.badges_achievements {
		background: rgba(0, 0, 0, 0.35);
		border-radius: 20px;
		padding: 30px;
		margin-top: 2.5rem !important;

		@include media($phone_all...) {
			max-width: 100%;
			padding: 10px;
		}

		h3 {
			@extend .subtitle;

			&:first-child {
				margin-top: 5px;
			}
		}
	}
}
