@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.activity_item {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	background-color: #00000059;
	border-radius: 10px;
	margin-top: 20px;
	padding: 12px;
	max-width: 600px;

	@include media($phone_all...) {
		padding: 3px;
	}

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
		max-width: 95%;

		@include media($phone_all...) {
			padding-right: 0.5rem;
		}

		svg {
			width: 20px;
			color: #08f996;
			margin-right: 18px;
		}

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			color: #e9e9e9;
			letter-spacing: normal;

			@include media($phone_all...) {
				max-width: 60%;
				font-size: 15px;
			}

			span {
				font-weight: 700;
				color: #fff;
			}
		}

		.date {
			margin-left: auto;
			font-size: 14px;
			line-height: 24px;
		}
	}

	& > p {
		img {
			position: relative;
			top: 0.4rem;
			width: 20px;
			height: 20px;
			margin: 0 2px;
		}
	}

	& > p,
	.rating {
		margin-left: 38px;
		margin-bottom: 5px;
		font-size: 13px;
	}

	.description {
		span {
			font-weight: 600;
		}
	}
}
