@import 'ThemeScss';

.reset_password_section {
	float: left;
	width: 100%;
	margin-top: 9.375rem;
	position: relative;

	.form_helper {
		width: 100%;
	}

	.reset_form {
		margin: 0 auto;
		border: 1.6px solid rgba(230, 232, 252, 0.35);
		padding: 68px 48px;
		position: relative;
		max-width: 600px;
		width: 100%;
		background: #121212;

		.back {
			color: #5dccf2;
			position: absolute;
			left: 0;
			top: -55px;
			font-size: 17px;
			font-weight: 600;
			line-height: 20px;
		}

		h2 {
			font-weight: 600;
			text-align: center;
			letter-spacing: 0.0625rem;
			position: absolute;
			top: -30px;
			padding: 0 20px;
			background: $bodyBg;
			left: 50%;
			transform: translateX(-50%);
			background: $darkGrayish;
			width: 360px;
			font-size: 42px;
			line-height: 52.5px;
			margin-top: 0;
		}

		p {
			text-align: center;
			color: $whiteColor;
			font-size: 15px;
		}

		form {
			margin-bottom: 2.5rem;
			padding: 0rem 2rem;
			margin-top: 30px;
		}

		.reset_password_form {
			> label {
				font-size: 15px;
				color: #a5abe2;
				margin-bottom: 8px;
			}

			.hide {
				display: none;
				visibility: hidden;
			}

			.yellow_bg_button_reset {
				margin-top: 5.4rem;
				background: linear-gradient(270deg, #ffc759 0%, #fea903 100%);
				color: #222222;
				text-transform: none;
				font-size: 1.4rem;
				padding: 0.7rem 0;
				line-height: inherit;
				height: inherit;
				width: 100%;
				text-align: center;
				height: 33px;
				font-size: 14px;
				font-weight: 600;
				margin-top: 54px;

				&:hover {
					background: linear-gradient(
							0deg,
							rgba(255, 255, 255, 0.35),
							rgba(255, 255, 255, 0.35)
						),
						linear-gradient(270deg, #ffc759 0, #fea903 100%);
				}
			}
		}
	}
}
