@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.personas {
	.persona_switcher {
		list-style: none;
		margin: 72px auto 0 auto;
		display: flex;
		justify-content: space-between;
		max-width: 950px;

		@include media($phone_all...) {
			max-width: 70%;
			margin-top: 43px;
		}

		> div {
			flex-direction: column;
			text-align: center;
			align-content: center;
			width: 201px;
			height: 201px;
			background: #15133a;
			border-radius: 5px;
			font-size: 20px;
			font-weight: 600;
			padding: 12px;
			position: relative;
			cursor: pointer;
			transition: all 500ms ease-in !important;

			@include media($phone_all...) {
				width: 90px;
				height: 90px;
				font-size: 13px;
				line-height: 13px;
			}

			.persona {
				opacity: 0.25;
			}

			img {
				width: 115px;
				display: inline-block;
				filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.15));
				position: relative;
				z-index: 1;

				@include media($phone_all...) {
					width: 40px;
				}
			}

			p {
				margin: 5px 0 0;
			}

			&.active {
				background: linear-gradient(
					180deg,
					rgba(195, 98, 255, 0.5) 0%,
					rgba(93, 69, 246, 0.5) 100%
				);
				backdrop-filter: blur(12.5px);

				.persona {
					opacity: 1;
				}
			}

			&.inactive {
				cursor: default;
				pointer-events: none;

				@include media($phone_all...) {
					display: none;
				}
			}

			&:hover {
				background: linear-gradient(
					180deg,
					rgba(195, 98, 255, 0.5) 0%,
					rgba(93, 69, 246, 0.5) 100%
				) !important;
				backdrop-filter: blur(12.5px);

				.persona {
					opacity: 1;
				}
			}

			.obscure {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: '';
				background: rgba(0, 0, 0, 0.25);
				backdrop-filter: blur(7.5px);
				border-radius: 5px;
				align-content: center;
				font-size: 16px;
				color: rgba(255, 255, 255, 0.6);
			}
		}
	}

	#description {
		width: 100%;
	}

	.persona_content,
	.persona_content_fan,
	.persona_content_creator,
	.persona_content_investor {
		display: none;
		margin-top: 70px;
		max-width: 1127px;
		width: 100%;

		@include media($phone_all...) {
			margin-top: 20px;
			margin-bottom: 48px;
		}

		.persona_explanation {
			display: flex;
			justify-content: space-evenly;

			.persona_hero {
				flex-direction: column;
				text-align: left;
				align-content: top;
				flex-basis: 49%;

				@include media($phone_all...) {
					display: flex;
					flex-basis: 99%;
					flex-direction: row-reverse;
					display: none;
				}

				.hero_img {
					position: relative;
					z-index: 3;
					max-width: 488px;
					width: 100%;
					box-sizing: border-box;
				}

				.hero_rating {
					position: relative;
					top: -50px;
					left: 45px;
					z-index: 4;
					max-width: 336px;
					box-sizing: border-box;
					box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
				}

				.hero_stakes {
					position: relative;
					z-index: 5;
					top: -97px;
					left: 122px;
					max-width: 320px;
					box-sizing: border-box;
					box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
				}

				.hero_two {
					@extend .hero_stakes;
					top: -250px;
					left: 20px;
				}

				.hero_three {
					@extend .hero_rating;
					top: -95px;
					left: 215px;
				}
			}

			@media (prefers-reduced-motion: no-preference) {
				.move_up {
					animation-name: wipe-enter;
					animation-duration: 2s;
					animation-iteration-count: 1;
				}
			}

			.person_description {
				flex-direction: column;
				flex-basis: 49%;
				text-align: left;
				align-content: top;
				filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.15));

				@include media($phone_all...) {
					display: flex;
					flex-basis: 90%;
				}

				h2 {
					margin-top: 10px;
					font-size: 30px;
					line-height: 36px;

					@include media($phone_all...) {
						font-size: 28px;
						line-height: 30px;
						margin-bottom: 10px;
					}
				}

				h3 {
					font-size: 24px;

					&.creator_steps {
						margin-left: 40px;

						@include media($phone_all...) {
							margin-left: 0px;
						}
					}
				}

				button {
					margin-top: 20px;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 10px 25px;
					gap: 10px;
					background: linear-gradient(
						180deg,
						rgba(195, 98, 255, 0.1) 0%,
						rgba(93, 69, 246, 0.5) 100%
					);
					border: 2px solid #fff;
					backdrop-filter: blur(12.5px);
					border-radius: 5px;
					color: #fff;
					font-size: 16px;
					cursor: pointer;

					&:hover {
						background: linear-gradient(
							180deg,
							rgba(195, 98, 255, 0.15) 0%,
							rgba(93, 69, 246, 1) 100%
						);
					}

					&.creator_steps {
						margin-left: 40px;

						@include media($phone_all...) {
							margin-left: 15px;
						}
					}

					@include media($phone_all...) {
						display: block !important;
						width: 50%;
					}
				}

				p {
					font-size: 16px;
					color: rgba(255, 255, 255, 0.6);

					strong {
						color: rgba(255, 255, 255, 1);
					}
				}

				ul {
					padding-left: 16px;

					li {
						font-size: 16px;
						color: rgba(255, 255, 255, 0.6);
						margin: 9px 0;
						list-style-image: url('~assets/images/homepage/list.png');
					}

					&.creator_steps {
						padding-left: 60px;

						@include media($phone_all...) {
							padding-left: 15px;
						}
					}
				}
			}
		}

		&.active {
			display: block;
		}
	}

	.persona_content_investor {
		button {
			margin-bottom: 40px;

			@include media($phone_all...) {
				margin-bottom: 20px;
			}
		}
	}
}
