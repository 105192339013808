.container {
	font-size: 15px;
	max-width: 1096px;
	margin: 8rem auto;
	width: 80%;
	position: relative;
	background: #121212;
	padding: 1rem;
	border-radius: 8px;

	h2 {
		letter-spacing: 0.03rem;
		font-size: 42px;
		margin-top: 0;
		margin-bottom: 2rem;
		font-weight: 300;
	}

	p {
		color: #628790;
		margin-bottom: 15px;
		letter-spacing: 0.3px;
		line-height: 19px;
	}

	ol {
		li {
			list-style-type: auto;
			letter-spacing: 1px;
			line-height: 1.6;
		}
	}
}
