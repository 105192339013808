@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.news {
	display: flex;
	margin-top: 80px;
	margin-bottom: 0px;
	position: relative;
	width: 95%;

	.news_item {
		width: 368px;
		height: 222px;
		display: inline-block;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 5px;
		padding: 20px;
		margin-left: 16px;

		img {
			max-width: 106px;
			bottom: 16px;
		}

		p {
			margin-top: 8px;
			font-size: 16px;
			color: rgba(255, 255, 255, 0.6);
		}
	}

	button {
		font-size: 42px;
		height: 20px;
		background: transparent;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.prev {
			left: -45px;
			height: 100%;
			z-index: 10;
			border-radius: 0;
			width: 50px;

			svg {
				font-size: 50px;
			}

			@include media($phone_all...) {
				display: none;
			}
		}

		&.next {
			right: -5%;
			height: auto;
			z-index: 10;
			border-radius: 0;
			width: 50px;

			svg {
				font-size: 50px;
			}

			@include media($phone_all...) {
				display: none;
			}
		}
	}
}
