@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.project_wizard,
.project_wizard_details,
.project_wizard_artwork {
	margin-top: 2rem;
	margin-bottom: 6rem;
	position: relative;
	z-index: 2;
	background: rgba(0, 0, 0, 0.75);
	border-radius: 20px;
	padding: 30px;
	max-width: 1140px !important;

	@include media($phone_all...) {
		margin-top: 4rem;
	}

	.header {
		width: 100%;
		display: inline-block;
		text-align: center;

		@include media($phone_all...) {
			display: block;
		}
	}

	.welcome {
		position: relative;
		margin-top: 40px;

		h1 {
			font-size: 1.75rem;
			font-weight: 300;

			@include media($phone_all...) {
				font-size: 1.45rem;
				margin: 0.75rem 0;
			}
		}

		p {
			font-size: 0.8rem;
			line-height: 1.1rem;
		}
	}

	.edit_controls {
		display: block;
		margin-left: auto;
		margin-top: 2rem;
		text-align: center;

		@include media($phone_all...) {
			text-align: center;
		}

		> div {
			scroll-margin-top: 100px;
		}

		button {
			padding: 0.25rem 1.5rem !important;
			margin: 0 2px;

			@include media($phone_all...) {
				padding: 0.25rem 0.75rem !important;
				margin-right: 0;
			}
		}

		.cancel_btn {
			height: initial;
			padding: 0.5rem 0.5rem;
			color: #fff;
			border: none;
			font-size: 0.9;
			text-transform: none;
		}

		.preview_btn {
			font-weight: 300;

			@include media($phone_all...) {
				display: none;
			}
		}

		.save_btn {
			font-weight: 700;
			opacity: 0.25;
			pointer-events: none;

			&.active {
				opacity: 1;
				pointer-events: initial;
			}
		}

		.approvalButton {
			font-size: 0.9rem;
			background: #4e4bde;
			border-radius: 2.5rem;
			color: #fff;
			height: 38px;
			line-height: inherit;
			text-transform: none;
			padding: 0 2.5rem;

			&:hover {
				background: #2b2972;
			}

			&:disabled {
				color: rgba(255, 255, 255, 0.25);
			}
		}
	}
}

.project_wizard_details {
	max-width: 800px !important;

	@include media($phone_all...) {
		max-width: 100% !important;
	}
}

.project_wizard_artwork {
}
