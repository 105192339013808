@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.newsreel_page {
	display: flex;
	flex-direction: column;
	max-width: 910px;
	align-items: center;
	margin: auto;
	z-index: 10;
	position: relative;

	.header {
		width: 100%;
		margin-top: 100px;

		@include media($phone_all...) {
			margin-top: 130px;
		}
	}

	.content {
		width: 100%;
		display: flex;

		@include media($phone_all...) {
			display: block;
		}

		.center {
			flex-grow: 1;
			margin-left: 2rem;
			@include media($phone_all...) {
				padding: 0 1rem;
				margin-left: 0;
				max-width: 100%;
			}

			.heading {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: -2rem;
				max-width: 600px;
				@include media($phone_all...) {
					display: block;
				}

				p {
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;

					@include media($phone_all...) {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
}
